import Moment from "react-moment";
import { FaRegEdit } from "react-icons/fa";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Services } from "Services";
import { warningSwal } from "Util/Toast";
import SkeletonLoad from "Components/TableLoad/load";
import CommonClasses from "../../Styles/Common.module.css"


function Payments() {
    const Navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const getAllCategories = () => {
        Services.Payments("GET", null, token)
            .then((response) => {
                console.log(response);
                setTimeout(() => {
                    setLoading(false);
                }, 200);
                if (response.Status === 1) {
                    setData(response.PaymentDetails);
                } else if (response.Status === 0) {
                    warningSwal("Warning", response.Message);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    useEffect(() => {
        getAllCategories();
    }, []);

    // const editHandler = (e) => {
    //     e.stopPropagation();
    //     e.preventDefault();
    //     let id = e.currentTarget.getAttribute("id");
    //     Navigate(`/hobbies/Update?id=${id}`);
    // };


    // const deleteHandler = async (e) => {
    //     e.stopPropagation();
    //     e.preventDefault();
    //     let id = e.currentTarget.getAttribute("id");
    //     try {
    //         const res = await Services.DeleteHobbie("DELETE", null, token, id)
    //         if (res.Status === 1) {
    //             SuccessSwal("Hobby Deleted", res.Message);
    //             getAllCategories(1);

    //         } else if (res.Status === 0) {
    //             warningSwal("Warning", res.Message);
    //         }
    //     } catch (err) {
    //         console.log(err);
    //         setLoading(false);
    //     }
    // };
    return (
        <>
            {loading ? (
                <SkeletonLoad
                    TableHead={["UserID", "Name", "Number", "PlanName", "PlanCost", "Amount", "RazorpayOrderId"]}
                />
            ) : data.length > 0 ? (
                <div className={CommonClasses["Table"]}>
                    <table>
                        <thead>
                            <tr>
                                <th>UserID</th>
                                <th>User Name</th>
                                <th>Mobile Number</th>
                                <th>PlanName</th>
                                <th>PlanCost</th>
                                <th>Amount</th>
                                <th>RazorpayOrderId</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item) => (
                                <tr
                                    id={item.UserID}
                                >
                                    <td>{item.UserID}</td>
                                    <td>{item.UserName}</td>
                                    <td>{item.MobileNumber}</td>
                                    <td>{item.PlanName}</td>
                                    <td>₹{item.PlanCost}</td>
                                    <td>₹{item.Amount}</td>
                                    <td>{item.RazorpayOrderId}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <div>
                    {" "}
                    <p>No Payments  Found</p>
                </div>
            )}
        </>
    );
}
export default Payments;
