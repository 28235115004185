import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { Services } from 'Services';
import { warningSwal } from 'Util/Toast';
import Commanclasses from '../../Styles/Common.module.css';
import { Tooltip, Legend, PieChart, Pie, Cell, ResponsiveContainer, BarChart, XAxis, YAxis, Bar } from 'recharts';
import Icon from '../../Assets/folder.png';
const UserCategoryCahrts = () => {
    const [loading, setLoading] = useState(false);
    const [locations, setLocations] = useState([]);
    const [ages, setAges] = useState([]);
    const [jobstatus, setJobstatus] = useState([]);
    const [genders, setGenders] = useState([]);
    const [citys, setCitys] = useState([]);

    // const {
    //     register,
    //     handleSubmit,
    //     reset,
    //     control,
    //     watch,
    //     setError,
    //     setValue,
    //     formState: { errors },
    // } = useForm({
    //     defaultValues: {
    //         Type: "week",
    //         Age: "18-21",
    //         Gender: 1,
    //         City: "Hyderabad",
    //         JobStatus: "Working"
    //     }
    // });
    // const selectedType = watch("Type");
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    useEffect(() => {
        onSubmit();
    }, [])

    const getLocations = async () => {
        try {
            const res = await Services.Locations("GET", null, token);
            if (res.Status === 1) {
                setLocations(res.Locations);
                // reset({
                //     City: "Hyderabad",
                // })
            } else if (res.Status === 0) {
                warningSwal("Error", res.Message);
            }
        } catch (err) {
            console.log(err);
        }
    }
    useEffect(() => {
        getLocations();
    }, []);

    const onSubmit = async () => {
        setLoading(true);

        try {
            const res = await Services.ChartsData2("POST", null, token);
            setLoading(false);
            if (res.Status === 1) {
                setAges(res?.Age);
                setJobstatus(res?.JobStatus);
                setGenders(res?.Gender);
                setCitys(res?.City);
            } else if (res.Status === 0) {
                warningSwal("warning", res.Message);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", 'red'];
    const COLORS2 = ["#00C49F", "#FFBB28"];
    const COLORS3 = ["#E77200", "#2E5894"];
    function generateRandomColorCodes(count) {
        const colors = [];
        for (let i = 0; i < count; i++) {
            const colorCode = '#' + Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0');
            colors.push(colorCode);
        }
        return colors;
    }
    const COLORS4 = generateRandomColorCodes(citys.length);

    return (
        <div className={Commanclasses.maincontent}>
            {/* <div className={Commanclasses.Formwrapper}>
                <form onSubmit={handleSubmit(onSubmit)} className={Commanclasses.forminputs}>

                    <div className={Commanclasses["form-control"]}>
                        <label htmlFor="Type">
                            Type{" "}
                            <span className="important">*</span>
                        </label>
                        <select
                            {...register("Type", {
                                required: "this field is required",
                                validate: NullCheck,
                            })}
                        >
                            <option value="">Select Type</option>
                            <option value={"week"}>Week</option>
                            <option value={"month"}>Month</option>
                            <option value={"custom"}>Custom Date</option>
                        </select>
                        {errors.Type && (
                            <span>{errors.Type.message}</span>
                        )}
                    </div>

                    {selectedType === "custom" && (
                        <>
                            <div className={Commanclasses["form-control"]}>
                                <label htmlFor="Type">From Date{" "}
                                    <span className="important">*</span>
                                </label>
                                <input
                                    type="date"
                                    {...register("FromDate", {
                                        required: "FromDate is required",
                                        validate: value => validateDates(value, "FromDate"),
                                    })}
                                />
                                {errors.FromDate && <span>{errors.FromDate.message}</span>}
                            </div>

                            <div className={Commanclasses["form-control"]}>
                                <label htmlFor="Type">To Date{" "}
                                    <span className="important">*</span>
                                </label>
                                <input
                                    type="date"
                                    {...register("ToDate", {
                                        required: "ToDate is required",
                                        validate: value => validateDates(value, "ToDate"),
                                    })}
                                />
                                {errors.ToDate && <span>{errors.ToDate.message}</span>}
                            </div>
                        </>
                    )}

                    <div className={Commanclasses["form-control"]}>
                        <label htmlFor="Type"> Age{" "}
                            <span className="important">*</span>
                        </label>
                        <select
                            {...register("Age", {
                                required: "Age is required",
                                validate: NullCheck,
                            })}
                        >
                            <option value=""> select Age</option>
                            <option value={"18-21"}>18-21</option>
                            <option value={"22-25"}>22-25</option>
                            <option value={"26-29"}>26-29</option>
                            <option value={"30-33"}>30-33</option>
                            <option value={"33+"}>33+</option>
                        </select>
                        {errors.ToDate && <span>{errors.ToDate.message}</span>}
                    </div>
                    <div className={Commanclasses["form-control"]}>
                        <label htmlFor="Gender"> Gender{" "}
                            <span className="important">*</span>
                        </label>
                        <select
                            {...register("Gender", {
                                required: "Gender is required",
                                valueAsNumber: true
                            })}
                        >
                            <option value={""}> select Gender</option>
                            <option value={1}>FeMale</option>
                            <option value={2}>Male</option>
                            <option value={3}>Others</option>
                        </select>
                        {errors.Gender && <span>{errors.Gender.message}</span>}
                    </div>
                    <div className={Commanclasses["form-control"]}>
                        <label htmlFor="City">City {" "}
                            <span className="important">*</span>
                        </label>
                        <select
                            {...register("City", {
                                required: "City is required",
                            })}
                        // defaultValue={"Hyderabad"}
                        >
                            <option value={""}>select City</option>
                            {
                                locations.map((item) => (
                                    <option key={item.Location} value={item.Location}>
                                        {item.Location}
                                    </option>
                                ))}
                        </select>
                        {errors.City && <span>{errors.City.message}</span>}
                    </div>
                    <div className={Commanclasses["form-control"]}>
                        <label htmlFor="JobStatus"> JobStatus{" "}
                            <span className="important">*</span>
                        </label>
                        <select
                            {...register("JobStatus", {
                                required: "JobStatus is required",
                            })}
                        >
                            <option value={""}>select Job Status</option>
                            <option value={"Working"}>Working</option>
                            <option value={"Not Working"}>Not Working</option>
                        </select>
                        {errors.JobStatus && <span>{errors.JobStatus.message}</span>}
                    </div>
                    <button disabled={loading}>
                        {" "}
                        {loading ? (
                            <ReactLoading
                                color="green"
                                type="spokes"
                                height={30}
                                width={30}
                            />
                        ) : (
                            "Submit"
                        )}
                    </button>
                </form>
            </div> */}
            <div className={Commanclasses["cartsContainer"]}>
                <div className={Commanclasses.Chart}>
                    <h2>Age wise users</h2>
                    {
                        ages.length > 0 ?
                            <PieChart width={400} height={400}>
                                <Pie
                                    data={ages}
                                    cx={200}
                                    cy={200}
                                    labelLine={false}
                                    dataKey="count"
                                    nameKey="Age"
                                    innerRadius={60}
                                    outerRadius={100}
                                >
                                    {ages.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                                <Legend />
                            </PieChart>
                            : <div className={Commanclasses.emptydata}>
                                <img src={Icon} alt='empty Image' />
                            </div>}
                </div>


                <div className={Commanclasses.Chart}>
                    <h2>JobStatus wise users</h2>
                    {
                        jobstatus.length > 0 ?
                            <PieChart width={400} height={400}>
                                <Pie
                                    data={jobstatus}
                                    dataKey="count"
                                    nameKey="JobStatus"
                                    cx={200}
                                    cy={200}
                                    labelLine={false}
                                    innerRadius={60}
                                    outerRadius={100}

                                >
                                    {jobstatus.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS2[index % COLORS2.length]} />
                                    ))}
                                </Pie>
                                <Tooltip />
                                <Legend />
                            </PieChart>
                            : <div className={Commanclasses.emptydata}>
                                <img src={Icon} alt='empty Image' />
                            </div>}
                </div>
                <div className={Commanclasses.Chart}>
                    <h2>Gender wise users</h2>

                    {
                        genders.length > 0 ?
                            <PieChart width={400} height={400}>
                                <Pie
                                    data={genders}
                                    dataKey="count"
                                    nameKey="Gender"
                                    cx={200}
                                    cy={200}
                                    labelLine={false}
                                    innerRadius={60}
                                    outerRadius={100}
                                >
                                    {genders.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS3[index % COLORS3.length]} />
                                    ))}
                                </Pie>

                                <Tooltip />
                                <Legend />
                            </PieChart>
                            : <div className={Commanclasses.emptydata}>
                                <img src={Icon} alt='empty Image' />
                            </div>}

                </div>
                <div className={Commanclasses.Chart}>
                    <h2>City wise users</h2>

                    {
                        citys.length > 0 ?
                            <BarChart
                                width={500}
                                height={400}
                                data={citys}
                                margin={{ top: 5, right: 10, left: 40, bottom: 20 }}
                                layout="vertical"
                            >
                                <XAxis type="number" />
                                <YAxis dataKey="City" type="category" stroke="#8884d8" />
                                <Tooltip />
                                <Bar dataKey="count" fill="#00C49F" barSize={40}>
                                    {citys.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS4[index]} />
                                    ))}
                                </Bar>
                            </BarChart>
                            : <div className={Commanclasses.emptydata}>
                                <img src={Icon} alt='empty Image' />
                            </div>}


                    {/* {
                        citys.length > 0 ?
                            <ResponsiveContainer width={400} height={400}>
                                <PieChart >
                                    <Pie
                                        data={citys}
                                        dataKey="count"
                                        nameKey="City"
                                        cx={200}
                                        cy={200}
                                        labelLine={false}
                                        innerRadius={60}
                                        outerRadius={100}
                                    >
                                        {citys.map((entry, index) => (
                                            <Cell key={`cell-${index}`}  />
                                        ))}
                                    </Pie>

                                    <Tooltip />
                                </PieChart>
                            </ResponsiveContainer>
                            : <div className={Commanclasses.emptydata}>
                                <img src={Icon} alt='empty Image' />
                            </div>} */}
                </div>
            </div>
        </div>
    )
}

export default UserCategoryCahrts