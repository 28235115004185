import Moment from "react-moment";
import { FaRegEdit, FaStreetView } from "react-icons/fa";
import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Services } from "Services";
import { SuccessSwal, warningSwal } from "Util/Toast";
import SkeletonLoad from "Components/TableLoad/load";
import CommonClasses from "Styles/Common.module.css";
import moment from "moment";
import { MdDelete, MdOutlinePreview } from "react-icons/md";
import ReactPaginate from "react-paginate";
import TabClasses from '../../Components/Tabs/Tabs.module.css';

function Users() {
    const Navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [caste, setCaste] = useState([]);
    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const itemsPerPage = 10;

    const [searchParams, setSearchParams] = useSearchParams();
    const initialTab = searchParams.get('tab') || 'ACTIVEUSERS';
    const [activeTab, setActiveTab] = useState(initialTab);
    const [searchType, setSearchType] = useState('id');
    const [searchQuery, setSearchQuery] = useState("");
    const searchTimeout = useRef(null);

    useEffect(() => {
        searchParams.set('tab', activeTab);
        setSearchParams(searchParams);
    }, [activeTab, searchParams, setSearchParams]);

    const TabsData = [
        { label: 'Active-Users', type: 'ACTIVEUSERS' },
        { label: 'Inactive Users', type: 'INACTIVEUSERS' },
        { label: 'Premium Users', type: 'PREMIUMUSERS' },
        { label: 'Created by Admin', type: 'CREATEDBYADMIN' },
        { label: 'Pending Verification', type: 'PENDINGVERIFICATION' }
    ];

    const castelist = async () => {
        try {
            const res = await Services.Cast("GET", null, token);
            if (res.Status === 1) {
                setCaste(res.Result);
            }
        } catch (err) {
            console.log(err)
        }
    }

    console.log('itemOffset', itemOffset)
    console.log('currentPage', currentPage)



    const getAllCategories = (page, type) => {

        Services.TabsFilter("GET", null, token, type)
            .then((response) => {
                console.log(response);
                setTimeout(() => {
                    setLoading(false);
                }, 200);
                if (response.Status === 1) {
                    setItemOffset(0);
                    setCurrentPage(0);
                    setData(response.Users);
                } else if (response.Status === 0) {
                    warningSwal("Warning", response.Message);
                }
            })
            .catch((err) => {
                setLoading(false);
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    useEffect(() => {
        castelist();
        getAllCategories(1, activeTab);
    }, [activeTab]);

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(data.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(data.length / itemsPerPage));
    }, [itemOffset, data]);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % data.length;
        setItemOffset(newOffset);
        setCurrentPage(event.selected);
        console.log('event', event);
    };


    const editHandler = (e) => {
        e.stopPropagation();
        e.preventDefault();
        let id = e.currentTarget.getAttribute("id");
        Navigate(`/users/Update?id=${id}`);
    };

    const user = (e) => {
        let id = e.currentTarget.getAttribute("id");
        Navigate(`/users/Details`, { state: id });
    }

    const deleteHandler = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        let body = {
            UserID: e.currentTarget.getAttribute("id")
        }
        try {

            const res = await Services.DeleteUser("DELETE", JSON.stringify(body), token);

            if (res.Status === 1) {
                SuccessSwal("User Deleted", res.Message);
                getAllCategories(1, activeTab);
            } else if (res.Status === 0) {
                warningSwal("Warning", res.Message);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const searchHandler = async (query, type) => {
        let body = {};
        if (type === 'id') {
            body.UserID = query;
        } else if (type === 'name') {
            body.UserName = query;
        } else if (type === 'number') {
            body.MobileNumber = query;
        }
        try {
            setLoading(true);
            const response = await Services.UsersSearch("POST", JSON.stringify(body), token, activeTab)
            setLoading(false);
            if (response.Status === 1) {
                setData(response.Users);
            } else if (response.Status === 0) {
                warningSwal("Warning", response.Message);
                setTimeout(() => {
                    getAllCategories(1, activeTab);
                }, 1000);
            }
        } catch (err) {
            setLoading(false);
            console.log(err);
            setTimeout(() => {
                getAllCategories(1, activeTab);
            }, 1000);
        };

    }
    const handleSearchInputChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);

        // if (searchType === 'id' && !/^[0-9]{1,10}$/.test(query)) {
        //     return;
        // }
        // if (searchType === 'name' && !/^[a-zA-Z\s]+$/.test(query)) {
        //     return;
        // }
        // if (searchType === 'number' && !/^[6-9][0-9]{9}$/.test(query)) {
        //     return;
        // }

        if (searchTimeout.current) {
            clearTimeout(searchTimeout.current);
        }

        searchTimeout.current = setTimeout(() => {
            if (query) {
                searchHandler(query, searchType);
            } else {
                getAllCategories(1, activeTab);
            }
        }, 1000);
    };

    const handleSearchTypeChange = (e) => {
        setSearchType(e.target.value);
        setSearchQuery('');
        getAllCategories(1, activeTab);
    };

    return (
        <div className={CommonClasses.main_cont}>
            <div className={CommonClasses.Btns}>
                <div className={TabClasses["Navigator"]}>
                    {
                        TabsData.map((tab) => (
                            <button
                                key={tab.type}
                                type="button"
                                className={activeTab === tab.type ? TabClasses["active"] : ""}
                                onClick={() => { setActiveTab(tab.type); }}
                            >
                                {tab.label}
                            </button>
                        ))}
                </div>
            </div>
            <div className={CommonClasses.serchFileter}>
                <select onChange={handleSearchTypeChange} value={searchType}>
                    <option value="id">User ID</option>
                    <option value="name">User Name</option>
                    <option value="number">Mobile Number</option>
                </select>
                <input
                    type="text"
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                    placeholder={`Search by ${searchType.replace('id', 'User ID').replace('name', 'User Name').replace('number', 'Mobile Number')}`}
                />
            </div>
            {loading ? (
                <SkeletonLoad
                    TableHead={["UserName", "MobileNumber", "DOB", "Age", "Gender", "MaritalStatus", "Caste", "Religion", "Gothram"]}
                />
            ) : data.length > 0 ? (
                <div className={CommonClasses["Table"]}>
                    <table>
                        <thead>
                            <tr>
                                <th>Profile ID</th>
                                <th>Name</th>
                                <th>Mobile</th>
                                <th>DOB</th>
                                <th>Age</th>
                                <th>Gender</th>
                                <th>MaritalStatus</th>
                                <th>Caste</th>
                                <th>Religion</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map((item) => {

                                let height = item.Height
                                if (typeof height === "number") {
                                    height = String(height).split(".")
                                    if (height.length == 1) {
                                        height = height[0] + "ft"
                                    }
                                    else {
                                        height = height[0] + "ft " + height[1] + "in"
                                    }
                                }
                                else {
                                    height = 'NA'
                                }

                                return (
                                    <tr
                                        id={item.UserID}
                                    >
                                        <td>{item.UserID}</td>
                                        <td>{item.UserName}</td>
                                        <td>{item.MobileNumber}</td>
                                        <td>{item?.DOB ? moment(item?.DOB).format("DD-MM-YYYY") : ""}</td>
                                        <td>{item.Age}</td>
                                        <td>
                                            {item.Gender == 1 ?
                                                <div
                                                    className={
                                                        CommonClasses[
                                                        "status-male"
                                                        ]
                                                    }>
                                                    <span>Female</span>
                                                </div>
                                                : item.Gender == 2 ?
                                                    <div
                                                        className={
                                                            CommonClasses[
                                                            "status-female"
                                                            ]
                                                        }>
                                                        <span>Male</span>
                                                    </div> : <div
                                                        className={
                                                            CommonClasses[
                                                            "status-others"
                                                            ]
                                                        }>
                                                        <span>Others</span>
                                                    </div>

                                            }
                                        </td>
                                        <td>
                                            {item.MaritalStatus === 1 ? (
                                                <div
                                                    className={
                                                        CommonClasses[
                                                        "status-male"
                                                        ]
                                                    }>
                                                    <span>Never Married</span>
                                                </div>
                                            ) : item.MaritalStatus === 2 ? (
                                                <div
                                                    className={
                                                        CommonClasses[
                                                        "status-others"
                                                        ]
                                                    }>
                                                    <span>Divorced</span>
                                                </div>

                                            ) : (
                                                ""
                                            )}
                                        </td>
                                        {/* <td>{height}</td> */}
                                        <td>{item?.Caste}</td>
                                        <td>{item?.Religion}</td>
                                        <td>{item?.IsPremium === 1 ?
                                            <div
                                                className={
                                                    CommonClasses[
                                                    "status-pre"
                                                    ]
                                                }>
                                                <span>Is Premium</span>
                                            </div>
                                            :
                                            <div
                                                className={
                                                    CommonClasses[
                                                    "status-No"
                                                    ]
                                                }>
                                                <span>No Premium</span>
                                            </div>
                                        }</td>
                                        <td>
                                            <div
                                                className={
                                                    CommonClasses["Action-btns"]
                                                }>

                                                <button
                                                    id={item.UserID}
                                                    onClick={user}>
                                                    <MdOutlinePreview />
                                                </button>
                                                <button
                                                    id={item.UserID}
                                                    onClick={editHandler}>
                                                    <FaRegEdit />
                                                </button>

                                                <button
                                                    id={item.UserID}
                                                    onClick={deleteHandler}>
                                                    <MdDelete />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <ReactPaginate
                        nextLabel=" >>"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={pageCount}
                        forcePage={currentPage}
                        previousLabel="<<"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item previous"
                        previousLinkClassName="page-link"
                        nextClassName="page-item next"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item break"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        disabledClassName="disabled"
                        renderOnZeroPageCount={null}
                    />
                </div>
            ) : (
                <div>
                    {" "}
                    <p>No Users Found</p>
                </div>
            )}
        </div>
    );
}

export default Users;
